@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 200;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 200;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 300;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 300;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 400;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 600;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 700;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
  }
  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 700;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf");
  }

  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 900;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf");
  }
  @font-face {
    font-family: "Source Sans Pro";
    font-weight: 900;
    font-style: italic;
    src: url("/fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf");
  }

  @font-face {
    font-family: "Evolventa";
    font-weight: 400;
    src: url("/fonts/evolventa/Evolventa-Regular.ttf");
  }

  @font-face {
    font-family: "Evolventa";
    font-weight: 800;
    src: url("/fonts/evolventa/Evolventa-Bold.ttf");
  }

  @font-face {
    font-family: "Marianne";
    font-weight: 800;
    src: url("/fonts/marianne/Marianne-Bold.woff2");
  }
  @font-face {
    font-family: "Marianne";
    font-weight: 300;
    src: url("/fonts/marianne/Marianne-Light.woff2");
  }
  @font-face {
    font-family: "Marianne";
    font-weight: 500;
    src: url("/fonts/marianne/Marianne-Medium.woff2");
  }
  @font-face {
    font-family: "Marianne";
    font-weight: 400;
    src: url("/fonts/marianne/Marianne-Regular.woff2");
  }

  .btn-blue {
    @apply px-4 py-2 font-bold text-white bg-blue-500 rounded;
  }

  .hero {
    @apply py-20;
  }

  .title {
    @apply text-5xl text-center;
    color: #333;
    line-height: 1.15;
  }

  input[type='text'], input[type='date'], input[type='tel'], input[type='email']{
    @apply py-2 border-gray-300 rounded;
  }

  /* Remove box-shadow in react select added by Tailwind reset forms */
  input[type='text']:focus {
    box-shadow: none;
  }

  input[type='radio'] {
    @apply border-gray-700;
  }

  input[type='checkbox'] {
    @apply border-gray-700 rounded;
  }

  a:hover {
    cursor: pointer;
  }

  .home-title {
    color: rgb(190, 225, 250);
  }

  .ariane-current {
    background-color: rgb(25,120,210);
  }

  .outline-button-text-red {
    color: rgb(225,0,0);
  }

  .outline-button:hover {
    background-color: #9ca3af;
  }

  .primary-button {
    background-color: rgb(25,120,210);
  }

  .primary-button:hover {
    background-color: #1460a8;
    color: white;
  }

  .disable-button {
    background-color: #a8a8a8; 
  }

  .disable-button:hover {
    background-color: #a8a8a8;
    color: white;
  }

  .mandatory::after {
    margin-left: 0.2em;
    content: "*";
    color: #EC130E;
  }

  .mandatory-red {
    color: #EC130E;
  }

  /*
  *:focus {
    outline: dotted #000000 3px;
  }*/

  .skipLinks {
    position: absolute;
    z-index: 999;
    left: 1rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
    list-style: none;
  }
  
  .link-skip-to-content {
    position: absolute;
    height: 1px;
    width: 1px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
  }

  .link-skip-to-content:focus {
    overflow: visible;
  }

  .data-tr:hover td {
    background-color: #f3f4f6;
  }

  .menu-utilisateur:hover {
    background-color: #e5e7eb;
  }

  .info {
    font-size: 1.1em;
    color: #353434;
    outline: 1px #353434 solid;
    padding: 0.5em;
    margin-left: 2em;
    margin-bottom: 2em;
    margin-top : 0.2em;
  }

  .arrow-icon {
    width: 1.5em;
    height: auto;
    transition: transform ease 0.5s;
    cursor: pointer;
  }
  
  .arrow-icon.expanded {
    transform: rotateZ(90deg);
    cursor: pointer;
  }

  .step-2-group-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .step-2-group-label-span {
    cursor: pointer;
  }

  .step-2-group-label-span.expanded {
    font-weight: bold;
  }

  .step-2-group-label-span:hover {
    text-decoration: underline;
    filter: brightness(-50%);
  }

  .step-2-group-label:focus-within {
    outline: dotted #000000 3px;
  }

  .stats-line {
    display: inline-flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
  }
}